//
//
//
//
//
//
//
//
//
//
//
//
//
//


import BigNumber from "bignumber.js";

export default {
	name: "province-echarts",
	computed: {
		list() {
			return this.$store.state['tobaccoQualityEcharts'].list;
		},
		colorList() {
			return this.$store.state['tobaccoQualityEcharts'].colorList;
		},
		pointerName() {
			return this.$store.state['tobaccoQualityEcharts'].pointerName;
		},
		queryParams() {
			return this.$store.state['tobaccoQualityEcharts'].queryParams;
		}
	},
	data() {
		return {
			varietiesArr: [],
			areaList: [],
			barSource: [],
			pointer: '',
			barOption: {
				title: {
					text: '',
					left: 'center',
				},
				legend: {
					top: '10%',
					type: 'scroll',//控制图例显示在一行
					orient: 'horizontal'//控制图例显示在一行
				},
				toolbox: {
					show: true,
					feature: {//可下载为图
						saveAsImage: {show: true}
					}
				},
				grid: {
					top: '30%',
					right: '10%',
				},
				label: {
					show: true,
					color: '#002',
					position: 'top',
					fontSize: "8px",
				},
				tooltip: {},
				dataset: {
					source: []
				},
				xAxis: {
					type: 'category',
					name: '产地',
					axisLabel: {interval: 0, rotate: 20}
				},
				yAxis: {
					type: "value",
					name: "品种类型", //y坐标轴的名字
					axisLine: {show: true},
					axisTick: {show: true}

				},
				dataZoom: [
					{
						xAxisIndex: 0, //这里是从X轴的0刻度开始
						show: true, //是否显示滑动条，不影响使用
						type: "slider", // 这个 dataZoom 组件是 slider 型 dataZoom 组件
						start: 0, // 从头开始。
						end: 30, // 一次性展示6个。
						height: 20,  //X轴滚动条宽度
					},
					{
						type: 'inside',
						start: 94,
						end: 100,
					},
				],
				series: []
			},
			pieOption: {
				title: {
					text: '',
					left: 'center'
				},
				legend: {
					top: '10%',
					type: 'scroll',//控制图例显示在一行
					orient: 'horizontal'//控制图例显示在一行
				},
				toolbox: {
					show: true,
					feature: {//可下载为图
						saveAsImage: {show: true}
					}
				},
				label: {
					formatter: '{d}%'
				},
				series: [
					{
						name: '',
						type: 'pie',
						radius: [50, 150],
						center: ['50%', '60%'],
						roseType: 'area',
						itemStyle: {
							borderRadius: 8
						},
						data: [],
					}
				]
			},
			pieNames: [],
		}
	},
	watch: {
		list(value) {
			if (value) {
				this.getChart();
			}
		},
	},
	methods: {
		/**
		 * 得到图形
		 */
		getChart() {
			// 处理数据
			if (this.list.length === 0) {
				this.barSource.series = [];
				this.barOption.dataset.source = [];
				this.barOption.title.text = "暂无数据";
				this.pieOption.title.text = "暂无数据";
				this.pieOption.series[0].data = [];
				return;
			}
			// 获取品种和行政区列表
			this.getArr();
			// 获取value值
			this.getValue();
			// 获取指标
			this.getPointer();
			this.barOption.title.text = this.queryParams.year[0] + "年-" +
				this.queryParams.grade + "-" + this.pointer + "-统计图";
			this.barOption.yAxis.name = this.pointerName;
			this.barOption.series = this.getBarSeries(this.varietiesArr);
			this.barOption.dataset.source = this.barSource;
			// 基于准备好的dom，初始化echarts实例
			this.getPie(true, '');
			this.$refs.bar.chart.on('click', 'series', (params) => {
				this.getPie(false, params.name);
			})
		},

		/**
		 * 获取指标
		 */
		getPointer() {
			this.pointer = this.pointerName;
			if (this.pointer.indexOf("(") !== -1) {
				this.pointer = this.pointer.substr(0, this.pointer.indexOf("("));
			}
		},

		/**
		 * 获取饼状图
		 * @param val
		 */
		getPie(val, area) {
			if (val) {
				area = this.barSource[1][0];
			}
			if (this.queryParams.type === '21' &&
				(this.pointer === '总糖' ||
					this.pointer === '还原糖' ||
					this.pointer === '总氮' ||
					this.pointer === '总植物碱' ||
					this.pointer === '钾离子' ||
					this.pointer === '氯离子')) {
				// 特殊指标的图
				this.specialPie(area);
			} else {
				// 平常指标的图
				this.usualPie(val, area);
			}
			this.pieOption.title.text = this.queryParams.year[0] + "年-" +
				area + "-" + this.queryParams.grade + "-" + this.pointer + "-占比图";
		},

		/**
		 * 等级	总糖 %	还原糖 %	 总氮 %	 总植物碱 %	钾离子 %	 氯离子 %
		 * B2F	24-31	21-26	2.0-2.6	 3.0-3.8	 >1.5	 0.1-0.6
		 * C3F	24-33	20-28	1.8-2.4	 2.3-3.2	 >1.7
		 * X2F	25-32	24-28	1.7-2.0	 1.5-2.3	 >1.8
		 * 获取特殊的图
		 * @param val
		 * @param name
		 */
		specialPie(area) {
			let values = [];
			// 根据不同的条件计算出不同的结果
			if (this.queryParams.grade === 'B2F') {
				switch (this.pointer) {
					case "总糖":
						values = this.getValues(area, 24, 31);
						break;
					case "还原糖":
						values = this.getValues(area, 21, 26);
						break;
					case "总氮":
						values = this.getValues(area, 2, 2.6);
						break;
					case "总植物碱":
						values = this.getValues(area, 3, 3.8);
						break;
					case "钾离子":
						values = this.getValues(area, 1.5, 100000);
						break;
					case "氯离子":
						values = this.getValues(area, 0.1, 0.6);
						break;
					default:
						break;
				}
			}
			if (this.queryParams.grade === 'C3F') {
				switch (this.pointer) {
					case "总糖":
						values = this.getValues(area, 24, 33);
						break;
					case "还原糖":
						values = this.getValues(area, 20, 28);
						break;
					case "总氮":
						values = this.getValues(area, 1.8, 2.4);
						break;
					case "总植物碱":
						values = this.getValues(area, 2.3, 3.2);
						break;
					case "钾离子":
						values = this.getValues(area, 1.7, 100000);
						break;
					case "氯离子":
						values = this.getValues(area, 0.1, 0.6);
						break;
					default:
						break;
				}
			}
			if (this.queryParams.grade === 'X2F') {
				switch (this.pointer) {
					case "总糖":
						values = this.getValues(area, 25, 32);
						break;
					case "还原糖":
						values = this.getValues(area, 24, 28);
						break;
					case "总氮":
						values = this.getValues(area, 1.7, 2);
						break;
					case "总植物碱":
						values = this.getValues(area, 1.5, 2.3);
						break;
					case "钾离子":
						values = this.getValues(area, 1.8, 100000);
						break;
					case "氯离子":
						values = this.getValues(area, 0.1, 0.6);
						break;
					default:
						break;
				}
			}
			this.pieOption.series[0].data = this.getPieSeries(this.pieNames, values);
		},
		/**
		 * 获取优质与不优质的数据
		 * @param area
		 * @param start
		 * @param end
		 * @param key
		 */
		getValues(area, start, end) {
			let arr = [start + '~' + end, '其他']
			this.pieNames = arr;
			let highQuality = new BigNumber("0");
			let lowQuality = new BigNumber("0");
			start = new BigNumber(start.toString());
			end = new BigNumber(end.toString());
			let values = [];
			this.list.forEach(c => {
				if (c) {
					let flagA = false;
					let flagB = false;
					let flagC = false;
					let flagD = false;
					if (c['province']) {
						flagA = c['province'].indexOf(area) !== -1 || area.indexOf(c['province']) !== -1;
					}
					if (c['city']) {
						flagB = c['city'].indexOf(area) !== -1 || area.indexOf(c['city']) !== -1;
					}
					if (c['county']) {
						flagC = c['county'].indexOf(area) !== -1 || area.indexOf(c['county']) !== -1;
					}
					if (c['town']) {
						flagD = c['town'].indexOf(area) !== -1 || area.indexOf(c['town']) !== -1;
					}
					if (flagA || flagB || flagC || flagD) {
						if (start.lte(new BigNumber(c['value'])) && end.gte(new BigNumber(c['value']))) {
							highQuality = highQuality.plus(new BigNumber("1"));
						} else {
							lowQuality = lowQuality.plus(new BigNumber("1"));
						}
					}
				}
			})
			values.push(highQuality.toNumber());
			values.push(lowQuality.toNumber());
			return values;
		},


		/**
		 * 获得平常的图
		 * @param val
		 * @param area
		 */
		usualPie(val, area) {
			// 是否是首次
			if (val) {
				let arr = [...this.barSource[1]];
				arr.shift();
				this.pieOption.series[0].data = this.getPieSeries(this.varietiesArr, arr);
			} else {
				let values = [];
				this.barSource.forEach(item => {
					if (item[0] === area) {
						values = [...item];
					}
				})
				values.shift();
				this.pieOption.series[0].data = this.getPieSeries(this.varietiesArr, values);
			}
		},

		/**
		 * 获取品种与行政区列表
		 * @param lists
		 * @returns {*[]}
		 */
		getArr() {
			this.varietiesArr = [];
			this.areaList = [];
			// 获取品种
			if (this.queryParams.varietiesType && this.queryParams.varietiesType.length > 0) {
				this.varietiesArr = this.queryParams.varietiesType;
			} else {
				// 以结果列表的为准
				this.list.forEach(item => {
					if (item.varieties && !this.varietiesArr.includes(item.varieties)) {
						this.varietiesArr.push(item.varieties);
					}
				})
			}
			// 获取行政区,选择了行政区列表就以行政区列表为准
			if (this.queryParams.areaCode && this.queryParams.areaCode.length > 0) {
				this.queryParams.areaCode.forEach(items => {
					if (items && items.length > 0) {
						this.areaList.push(items[items.length - 1]);
					}
				})
			} else {
				// 以结果列表的为准
				this.list.forEach(item => {
					// 默认使用两级
					if (item['province'] === '云南省') {
						if (!this.areaList.includes(item['city'])) {
							this.areaList.push(item['city']);
						}
					}
				})
			}
		}
		,

		/**
		 * 获取
		 * @param value
		 */
		getValue() {
			this.barSource = [];
			let source = [];
			let breed = ['产地'];
			this.varietiesArr.forEach(item => {
				breed.push(item);
			})
			source.push(breed);
			this.areaList.forEach(a => {
				let temp = [a];
				this.varietiesArr.forEach(b => {
					let count = new BigNumber("0");
					let sum = new BigNumber("0");
					this.list.forEach(c => {
						if (a && b && c) {
							let flagA = false;
							let flagB = false;
							let flagC = false;
							let flagD = false;
							if (c['province']) {
								flagA = c['province'].indexOf(a) !== -1 || a.indexOf(c['province']) !== -1;
							}
							if (c['city']) {
								flagB = c['city'].indexOf(a) !== -1 || a.indexOf(c['city']) !== -1;
							}
							if (c['county']) {
								flagC = c['county'].indexOf(a) !== -1 || a.indexOf(c['county']) !== -1;
							}
							if (c['town']) {
								flagD = c['town'].indexOf(a) !== -1 || a.indexOf(c['town']) !== -1;
							}
							if ((flagA || flagB || flagC || flagD) && c['varieties'] === b) {
								let value = new BigNumber(c['value']);
								if (value > 0) {
									sum = sum.plus(value);
									count = count.plus(new BigNumber("1"));
								}
							}
						}
					})

					temp.push(count > 0 ? sum.div(count).dp(2).toNumber() : 0);
				})
				source.push(temp);
			})
			this.barSource = source;
		}
		,


		/**
		 * 拼接柱状图的series
		 * @param names
		 * @returns {*[]}
		 */
		getBarSeries(names) {
			let series = [];
			let size = this.colorList.length;
			if (names) {
				for (let i = 0; i < names.length; i++) {
					series.push({
						type: 'bar',
						name: names[i],
						itemStyle: {
							normal: {
								//颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
								color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 0.8, [{
									offset: 0,
									color: this.colorList[i % size][0]
								}, {
									offset: 1,
									color: this.colorList[i % size][1]
								}])
							}
						}
					})
				}
			}
			return series;
		}
		,

		/**
		 * 拼接饼图的series
		 * @param names
		 * @param values
		 * @returns {*[]}
		 */
		getPieSeries(names, values) {
			let series = [];
			let size = this.colorList.length;
			if (names) {
				for (let i = 0; i < names.length; i++) {
					series.push({
						value: values[i],
						name: names[i],
						itemStyle: {
							normal: {
								//颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
								color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 0.8, [{
									offset: 0,
									color: this.colorList[i % size][0]
								}, {
									offset: 1,
									color: this.colorList[i % size][1]
								}])
							}
						},
					})
				}
			}
			return series;
		}
	}
}
