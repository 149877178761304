//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import exportHeader from "../../../components/tobacco/export-header";
import quality from "../tobacco-quality/quality";
import {dataUtil} from '../../../utils/data-util';
import indicatorCustomizationQuality from "../tobacco-management/indicator-customization-quality";

export default {
	name: "table-list",
	components: {
		exportHeader,
		quality,
		indicatorCustomizationQuality
	},
	inject: [
		"hideLoading",
		"showLoading"
	],
	computed: {
		areaTree() {
			return this.$store.state['areaManagement'].areaTree;
		},
		dialogVisible() {
			return this.$store.state['tobaccoQuality'].dialogVisible;
		},
		tags() {
			return this.$store.state['tobaccoQuality'].tagsList;
		},
		types() {
			return this.$store.state['tobaccoQuality'].type;
		},
		exportFlag() {
			return this.$store.state['tobaccoQuality'].exportFlag;
		},
		queryParams() {
			return this.$store.state['tobaccoQuality'].queryParams;
		}
	},
	data() {
		return {
			dict: [],
			pointerType: '全部',
			dataType: [],
			varietiesTypes: '',
			varietiesList: [],
			grade: '',
			gradeList: [],
			type: '1',
			quaDictType: 'tobacco_quality',
			varietiesDictType: 'varieties_type',
			gradeDictType: 'tobacco_level',
			areaTreeProps: {
				'value': 'code',
				'label': 'name',
				'children': 'childrenList',
				'checkStrictly': true
			},
			dataTimes: [],
			year: '',
			indexList: [],
		}
	},
	async mounted() {
		await this.getSelects();
		this.changeData(this.type);
		this.changeGrade(this.grade);
		await this.search();
		this.hideLoading();
	},
	methods: {
		/**
		 * 查询
		 */
		async search() {
			this.showLoading();
			// 传参
			await dataUtil.changeParamsSingle("pageNum", 1);
			await dataUtil.search();
			await this.$store.dispatch("tobaccoQuality/changeExportFlag", true);
			this.hideLoading();
		},

		/**
		 * 更改数据类型
		 * @param val
		 */
		changeData(val) {
			let pointer = [];
			let temp = [];
			temp.push("2");
			temp.push(val);
			dataUtil.getType(temp);
			// 获取选取值的id
			let id = null;
			this.dataType.forEach(item => {
				if (item.value === val) {
					id = item.id;
				}
			})
			// 更改指标类型
			if (id) {
				this.dict.forEach(item => {
					if (item.parentId === id) {
						pointer.push(item);
					}
				})
			}
			this.$store.dispatch("tobaccoQuality/changePointerList", pointer);
			// 更改品种
			this.change1(val);
		},

		/**
		 * 更改年份
		 */
		changeYear() {
			dataUtil.changeParamsSingle("year", this.year);
		},

		/**
		 * 更改产地
		 * @param val
		 */
		changeArea(val) {
			dataUtil.changeParamsSingle("areaCode", dataUtil.getAreaName(val, this.areaTree));
		},

		/**
		 * 获取品种
		 * @param val
		 * @returns {Promise<void>}
		 */
		async change1(val) {
			let res = await this.$store.dispatch("tobaccoQuality/getTypes",
				{
					'type': "2" + val,
					'grade': this.grade
				});
			this.varietiesList = res.data;
		},

		/**
		 * 更改品种
		 */
		changeType() {
			dataUtil.changeParamsSingle("varietiesType", this.varietiesTypes);
		},

		/**
		 * 更改等级
		 */
		changeGrade(val) {
			this.grade = val;
			dataUtil.changeParamsSingle("grade", val);
			this.change1(this.type);
		},

		/**
		 * 关闭标签
		 * @param tag
		 */
		handleClose(tag) {
			let res = [...this.queryParams.index];
			let i = res.length;
			while (i--) {
				if (res[i].id === tag.id) {
					res.splice(i, 1);
				}
			}
			this.tags.splice(this.tags.indexOf(tag), 1);
			dataUtil.changeParamsSingle("index", res);
			// 查询数据
			this.search();
		},

		/**
		 * 显示对话框
		 */
		showDialog() {
			// 初始化列表
			let res = [...this.queryParams.index];
			// 还原指标列表
			this.indexList = [...dataUtil.reductionIndexList(res)];
			// 显示弹窗
			this.$store.dispatch("tobaccoQuality/changeDialogVisible", true);
		},

		/**
		 * 获取下拉单数据
		 */
		async getSelects() {
			this.dataType = [];
			// 数据类型
			let res = await this.$store.dispatch('dict/getDictEntity',
				{'type': [this.quaDictType, this.varietiesDictType, this.gradeDictType]});
			this.dict = res.data;
			if (this.dict) {
				// 获取检测类型与品种类型
				this.dict.forEach(item => {
					if (item.levelFlag === 1 && item.type === this.quaDictType) {
						this.dataType.push(item);
					}
					if (item.type === this.gradeDictType) {
						this.gradeList.push(item);
					}
					// 默认选中第一个等级
					if (this.gradeList.length > 0) {
						this.changeGrade(this.gradeList[0].value);
					}
				})
			}
			// 年份
			this.dataTimes = dataUtil.getDateTimes();
			// 行政树
			await this.$store.dispatch('areaManagement/getAreaTree');
		}

	}
}
