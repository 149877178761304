//
//
//
//
//
//
//
//
//
//

import PageHeader from "../../../components/page-header";
import headCheckQuality from "../../business/tobacco-management/head-check-quality";

export default {
	name: "index",
	components: {
		PageHeader,
		headCheckQuality,
	},
}


