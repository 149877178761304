//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import tableList from "../tobacco-quality/table-list";
import yearSelectIndex from "../tobacco-quality-echarts/year-select-index";
import varietiesSelectIndex from "../tobacco-quality-echarts/varieties-select-index";
import provinceSelectIndex from "../tobacco-quality-echarts/province-select-index";

export default {
	name: "head-check-quality",
	components: {
		tableList,
		yearSelectIndex,
		varietiesSelectIndex,
		provinceSelectIndex
	},
	computed: {
		// 区分选择的图
		headCheck() {
			return this.$store.state['tobaccoQuality'].headCheck;
		}
	},
	data() {
		return {
			/**
			 * 默认是数据列表
			 */
			btn: 1,
			url: this.$store.dispatch('tobaccoQuality/changeHeadCheck', '1'),
		}
	},
	methods: {
		changeCheck() {
			this.btn = 1;
			this.$store.dispatch('tobaccoQuality/changeHeadCheck', '1');
			this.$store.dispatch('tobaccoQuality/changeTagsList', []);
			this.$store.dispatch('tobaccoQuality/changeParamsSingle', {key: 'index', value: []});
			this.$store.dispatch('tobaccoQualityEcharts/changeParamsSingle', {key: 'index', value: []});
		},
		changeCheck1() {
			this.btn = 2;
			this.$store.dispatch('tobaccoQuality/changeHeadCheck', '2');
			this.$store.dispatch('tobaccoQuality/changeTagsList', []);
			this.$store.dispatch('tobaccoQuality/changeParamsSingle', {key: 'index', value: []});
			this.$store.dispatch('tobaccoQualityEcharts/changeParamsSingle', {key: 'index', value: []});
		},
		changeCheck2() {
			this.btn = 3;
			this.$store.dispatch('tobaccoQuality/changeHeadCheck', '3');
			this.$store.dispatch('tobaccoQuality/changeTagsList', []);
			this.$store.dispatch('tobaccoQuality/changeParamsSingle', {key: 'index', value: []});
			this.$store.dispatch('tobaccoQualityEcharts/changeParamsSingle', {key: 'index', value: []});
		},
		changeCheck3() {
			this.btn = 4;
			this.$store.dispatch('tobaccoQuality/changeHeadCheck', '4');
			this.$store.dispatch('tobaccoQuality/changeTagsList', []);
			this.$store.dispatch('tobaccoQuality/changeParamsSingle', {key: 'index', value: []});
			this.$store.dispatch('tobaccoQualityEcharts/changeParamsSingle', {key: 'index', value: []});
		}
	}
}
