//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {dataUtil} from '../../../utils/data-util';
import indicatorCustomizationQuality from "../tobacco-management/indicator-customization-quality";
import {echartsUtil} from "../../../utils/echarts-util";
import yearEcharts from "../tobacco-quality-echarts/year-echarts";

export default {
    name: "year-select-index",
    components: {
        indicatorCustomizationQuality,
        yearEcharts
    },
    inject: ["hideLoading", "showLoading"],
    computed: {
        // 行政树
        areaTree() {
            return this.$store.state['areaManagement'].areaTree;
        },
        // 控制指标编辑界面
        dialogVisible() {
            return this.$store.state['tobaccoQuality'].dialogVisible;
        },
        // 标签
        tags() {
            return this.$store.state['tobaccoQuality'].tagsList;
        },
		queryParams() {
			return this.$store.state['tobaccoQualityEcharts'].queryParams;
		}
    },
    data() {
        return {
            // 查询项目
            type: '1',
            dataType: [],
            // 指标
            pointerType: '',
            pointerList: [],
            // 年份
            dataTimes: [],
            yearMulti: [],
            // 行政区
            areaTreeProps: {
                'value': 'code',
                'label': 'name',
                'children': 'childrenList',
                'checkStrictly': true
            },
            // 行政区
            areaTreePropsMulti: {
                'value': 'code',
                'label': 'name',
                'children': 'childrenList',
                'checkStrictly': true,
                'multiple': true
            },
            // 产地
            areaMulti: [],
            // 品种
            varietiesSingle: '',
            varietiesList: [],
            dict: [],
            quaDictType: 'tobacco_quality',
            varietiesDictType: 'varieties_type',
            //等级
            grade: '',
            gradeList: [],
            gradeDictType: 'tobacco_level',
			indexList: [],
        }
    },
    async mounted() {
        this.getType(this.type);
        await this.getSelects();
        await this.changeData(this.type);
        // 初始化数据
        await echartsUtil.changeParamsSingle("year", []);
        await echartsUtil.changeParamsSingle("areaCode", []);
        await this.search();
        this.hideLoading();
    },
    methods: {
        /**
         * 查询
         */
        async search() {
            this.showLoading();
            await echartsUtil.search();
            this.hideLoading();
        },

        /**
         * 更改数据类型
         * @param val
         */
        async changeData(val) {
            this.pointerList = [];
            this.getType(val);
            // 获取选取值的id
            let id = null;
            this.dataType.forEach(item => {
                if (item.value === val) {
                    id = item.id;
                }
            })
            // 更改指标类型
            if (id) {
                this.dict.forEach(item => {
                    if (item.parentId === id) {
                        this.pointerList.push(item);
                    }
                })
                if (this.pointerList.length > 0) {
                    this.pointerType = this.pointerList[0].value;
                    this.changePointer();
                }
            }
            await this.$store.dispatch("tobaccoQuality/changePointerList", this.pointerList);
            // 更改品种
            await this.getVarieties(val);
        },
        /**
         * 更改指标
         */
        changePointer() {
            echartsUtil.changeParamsSingle("pointerType", this.pointerType);
            this.pointerList.forEach(item => {
                if (item.value === this.pointerType) {
                    this.$store.dispatch("tobaccoQualityEcharts/changePointerName", item.name);
                }
            })
        },

        /**
         * 获取类型
         * @param val
         */
        getType(val) {
            if (val) {
                let temp = [];
                temp.push("2");
                temp.push(val);
                echartsUtil.getType(temp);
            }
        },

        /**
         * 更改年份
         */
        changeYearMulti() {
            echartsUtil.changeParamsSingle("year", this.yearMulti);
        },

        /**
         * 更改产地
         * @param val
         */
        changeAreaMulti(val) {
            let arr = echartsUtil.getAreaName(val, this.areaTree);
            echartsUtil.changeParamsSingle("areaCode", arr);
        },

        /**
         * 获取品种
         * @param val
         * @returns {Promise<void>}
         */
        async getVarieties(val) {
            let res = await this.$store.dispatch("tobaccoQuality/getTypes",
                {
                    'type': "2" + val,
                    'grade': this.grade
                });
            this.varietiesList = res.data;
            // 初始化品种
            this.varietiesSingle = this.varietiesList[0];
            this.changeTypeSingle();
        },

        /**
         * 更改品种
         */
        changeTypeSingle() {
            echartsUtil.changeParamsSingle("varietiesType", [this.varietiesSingle]);
        },

        /**
         * 更改等级
         */
        changeGrade(val) {
            this.grade = val;
            echartsUtil.changeParamsSingle("grade", val);
        },

        /**
         * 关闭标签
         * @param tag
         */
        handleClose(tag) {
			let res = [...this.queryParams.index];
            let i = res.length;
            while (i--) {
                if (res[i].id === tag.id) {
                    res.splice(i, 1);
                }
            }
			this.tags.splice(this.tags.indexOf(tag), 1);
            echartsUtil.changeParamsSingle("index", res);
            echartsUtil.search();
        },

        /**
         * 显示对话框
         */
        showDialog() {
			// 初始化列表
			let res = [...this.queryParams.index];
			// 还原指标列表
			this.indexList = [...dataUtil.reductionIndexList(res)];
			// 显示弹窗
            this.$store.dispatch("tobaccoQuality/changeDialogVisible", true);
        },

        /**
         * 获取下拉单数据
         */
        async getSelects() {
            this.dataType = [];
            // 数据类型
            let res = await this.$store.dispatch('dict/getDictEntity',
                {'type': [this.quaDictType, this.varietiesDictType, this.gradeDictType]});
            this.dict = res.data;
            if (this.dict) {
                // 获取检测类型与品种类型
                this.dict.forEach(item => {
                    if (item.levelFlag === 1 && item.type === this.quaDictType) {
                        this.dataType.push(item);
                    }
                    if (item.type === this.gradeDictType) {
                        this.gradeList.push(item);
                    }
                    // 默认选中第一个等级
                    if(this.gradeList.length > 0){
                        this.changeGrade(this.gradeList[0].value);
                    }
                })
            }
            if (this.dataType && this.dataType.length > 0) {
                this.pointerType = this.dataType[0].value;
                await echartsUtil.changeParamsSingle("pointerType", this.pointerType);
            }
            // 年份
            this.dataTimes = dataUtil.getDateTimes();
        }
    }
}
