// import store from "@/store";

import store from "../store";

let type = '';

let areaName = [];

export const echartsUtil = {

	/**
	 * 查询
	 */
	async search() {
		switch (type) {
			case "1":
			case "2":
			case "3":
			case "4":
			case "5":
			case "6":
			case "7":
				await store.dispatch("tobaccoEnvironmentEcharts/search");
				break;
			case "21":
			case "22":
			case "23":
			case "24":
			case "25":
			case "26":
			case "27":
				await store.dispatch("tobaccoQualityEcharts/search");
				break;
			default:
				break;
		}
	},

	/**
	 * 获取类型
	 * @param val
	 * @returns {string}
	 */
	async getType(val) {
		if (val && val.length > 0) {
			if (val[0] === "1") {
				type = val[1];
			}
			if (val[0] === "2") {
				type = "2" + "" + val[1];
			}
		}
		await this.changeParamsSingle("type", type);
		return type;
	},

	/**
	 * 修改单个key的值
	 * @param key
	 * @param value
	 * @returns {Promise<void>}
	 */
	async changeParamsSingle(key, value) {
		switch (type) {
			case "1":
			case "2":
			case "3":
			case "4":
			case "5":
			case "6":
			case "7":
				await store.dispatch('tobaccoEnvironmentEcharts/changeParamsSingle', {
					'key': key,
					'value': value
				});
				break;
			case "21":
			case "22":
			case "23":
			case "24":
			case "25":
			case "26":
			case "27":
				await store.dispatch('tobaccoQualityEcharts/changeParamsSingle', {
					'key': key,
					'value': value
				});
				break;
			default:
				break;
		}
	},

	/**
	 * 根据选中的行政区code列表获取行政树里对应的名称列表
	 * @param checkArea 选中的行政code
	 * @param areaTree 行政树
	 * @returns {*[]}
	 */
	getAreaName(checkArea, areaTree) {
		let arr = [];
		checkArea.forEach(items => {
			areaName = [];
			items.forEach(item => {
				this.getChildrenList(areaTree, item);
			})
			arr.push(areaName);
		})
		return arr;
	},

	/**
	 * 获取名称
	 * @param val
	 * @param val1
	 */
	getChildrenList(areaTree, code) {
		areaTree.forEach(item => {
			if (item.code === code) {
				areaName.push(item.name)
				return;
			}
			if (item.childrenList != null && item.childrenList.length > 0) {
				this.getChildrenList(item.childrenList, code);
			}
		})
	},
}
