//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {dataUtil} from "@/utils/data-util";
import {tableColumnData} from "@/utils/table-column-data";

export default {
	name: "export-header",
	inject: ["hideLoading", "showLoading"],
	props: {
		bindType: {
			type: String,
			default: ''
		}
	},
	computed: {
		checkedColumns() {
			return this.$store.state['tobaccoManagement'].columns;
		}
	},
	data() {
		return {
			drawer: false,
			columns: [],
			checkAll: false,
			checkColumn: [],
			isIndeterminate: true
		}
	},
	methods: {
		/**
		 * 导出
		 */
		async exportExcel() {
			this.showLoading();
			await dataUtil.downloadExcel();
			this.hideLoading();
		},
		/**
		 * 筛查数据
		 */
		sift() {
			this.drawer = true;
			this.columns = [];
			tableColumnData[this.bindType].forEach(item => {
				this.columns.push(item.label);
			});
			if (this.checkedColumns) {
				this.checkColumn = [];
				this.checkedColumns.forEach(item => {
					if (item.show) {
						this.checkColumn.push(item.label);
					}
				})
			}
		},
		/**
		 * 全选
		 * @param val
		 */
		handleCheckAllChange(val) {
			this.checkColumn = val ? this.columns : [];
			this.isIndeterminate = false;
		},
		/**
		 * 选择
		 * @param value
		 */
		handleCheckedCitiesChange(value) {
			let checkedCount = value.length;
			this.checkAll = checkedCount === this.columns.length;
			this.isIndeterminate = checkedCount > 0 && checkedCount < this.columns.length;
		},

		/**
		 * 确定
		 */
		submit() {
			if (this.checkColumn) {
				tableColumnData[this.bindType].forEach(a => {
					if (this.checkColumn.includes(a.label)) {
						a.show = true;
					} else {
						a.show = false;
					}
				})
			}
			this.$store.dispatch("tobaccoManagement/changeColumns", tableColumnData[this.bindType]);
			this.drawer = false;
		},
	}
}
